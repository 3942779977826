// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	min-height: 85px;
	background: $blue;
	transition: 0.5s;

	&::after {
		content: url('/images/header-wave.svg');

		@include media-breakpoint-down(lg) {
			content: url('/images/header-wave.png');
			content-visibility: auto;
		}

		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin-top: -1px;
		font-size: 0;
		line-height: 0;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				padding-top: 15px;
				padding-bottom: 15px;
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;

				@include media-breakpoint-up(xl) {
					padding: 20px 30px 20px 290px;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: auto;

		.navbar-toggler {
			width: 40px;
			height: 40px;
			padding: 0;
			border: none;
			background: $blue-dark;
			border-radius: 100%;
			box-shadow: $shadow;
			color: $white;
			font-size: 24px;
			transition: color 0.5s;

			i {
				line-height: 40px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 15px;
		width: 300px;
		left: 60px;
		transition: 0.5s;

		@include media-breakpoint-down(lg) {
			width: 250px;
			top: 15px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			width: 145px;

			@include media-breakpoint-down(lg) {
				width: 100px;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;
			margin-top: 30px;

			.navbar-nav {
				>.nav-item {
					&:not(:last-of-type) {
						border-bottom: 1px solid rgba($white, 0.5);
					}

					font-size: 16px;
					font-weight: 700;

					>.nav-link {
						display: flex;
						align-items: center;
						color: $white;

						&:hover {
							color: $yellow;
						}
					}

					&.active {
						>.nav-link {
							color: $yellow;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $white;

								&:hover {
									color: $yellow;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $yellow;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin: 0 auto;

				>.nav-item {
					margin: 0 5px;
					font-size: 16px;
					font-weight: 700;

					>.nav-link {
						color: $white;

						&:hover {
							color: $yellow;
						}
					}

					&.active {
						>.nav-link {
							color: $yellow;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;

						.nav-item {
							font-size: 16px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $orange;
								}
							}

							&.active {
								.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		ul {
			li {
				a {
					@extend .btn;
					@extend .btn-primary;

					@include media-breakpoint-down(lg) {
						padding: 7px 1rem !important;
					}

					@include media-breakpoint-up(xl) {
						font-size: 20px !important;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		@include media-breakpoint-down(xs) {
			z-index: 10;
			position: absolute;
			top: 63px;
			right: 20px;
		}

		.language {
			.nav-item {
				+li {
					margin-left: 10px;

					@include media-breakpoint-down(xs) {
						margin-left: 5px;
					}
				}

				.nav-link {
					img {
						@include media-breakpoint-up(xl) {
							width: 36px;
							height: 36px;
						}
					}
				}
			}
		}
	}
}

&.home {
	.header {
		position: fixed;
	}
}

&.accommodation-book,
&.accommodation-search-book,
&.faq-overview {
	.header {
		position: relative;

		.logo {
			width: 145px;

			@include media-breakpoint-down(lg) {
				width: 100px;
			}
		}
	}
}

&.navbar-collapse-active {
	.header {
		.logo {
			@include media-breakpoint-down(lg) {
				width: 100px;
			}
		}
	}
}
