// mini-sab
.mini-sab {
	@include media-breakpoint-up(lg) {
		padding-bottom: 30px;
	}

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			min-height: 150px;
			margin: 0;
			padding: 15px 5px 20px 5px;
			border-radius: 20px;
			background: $yellow;
			color: #fff;

			@include media-breakpoint-down(md) {
				margin-top: -150px;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: -30px;
				padding: 10px 35px 15px 35px;
				border-radius: 75px;
			}

			// heading
			.heading {
				font-size: 24px;
				text-align: center;
				font-family: $font-family-tertiary;

				@include media-breakpoint-down(md) {
					margin-bottom: 10px;
				}

				@include media-breakpoint-up(lg) {
					font-size: 36px;
				}
			}

			// jump
			a.jump {
				display: none;
				position: absolute;
				left: 0;
				right: 0;
				bottom: -35px;
				width: 40px;
				height: 40px;
				margin: 0 auto;
				border-radius: 100%;
				background-color: $yellow;
				color: $blue-dark;
				font-size: 24px;
				line-height: 40px;
				text-align: center;
				text-decoration: none;

				&::before {
					content: '\f0ab';
					display: block;
					font-weight: 900;
					font-family: $font-awesome;
				}

				&:hover {
					transform: translateY(5px);
				}
			}
		}
	}
}

&.home {
	.mini-sab {
		a.jump {
			@include media-breakpoint-up(lg) {
				display: block !important;
			}
		}
	}
}
