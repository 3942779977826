// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// mixed-collection-section
// =========================================================================
.mixed-collection-section {
	position: relative;
	margin: 6vh 0;
	padding: 0 15px 100px 15px;
	background: $green;
	overflow: hidden;

	&::before {
		content: url('/images/wave-top-green.svg');

		@include media-breakpoint-down(lg) {
			content: url('/images/wave-top-green.png');
		}

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding-top: 100px;
		font-size: 0;
		line-height: 0;
		background: $cream;
	}

	&::after {
		content: url('/images/wave-bottom-green.svg');

		@include media-breakpoint-down(lg) {
			content: url('/images/wave-bottom-green.png');
		}

		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background: $cream;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {

					.card {
						@extend .default-card;
						transition: 0.5s;
					}

					&:nth-child(1),
					&:nth-child(5) {
						@include media-breakpoint-up(lg) {
							@include make-col(12);
						}

						.card {
							@include media-breakpoint-down(lg) {
								transform: rotate(-3deg);
							}

							@include media-breakpoint-up(lg) {
								flex-direction: row;

								.card-image {
									width: 50%;

									&::before {
										top: 0;
										left: auto;
										right: -8px;
										bottom: 0;
										width: 16px;
										height: auto;
										background: url('/images/card-wave-vertical.svg');

									}
								}

								.card-body {
									width: 50%;
									margin: auto;

									@include media-breakpoint-up(xl) {
										padding: 30px 45px;
									}
								}
							}
						}
					}

					&:nth-child(5) {
						.card {
							@include media-breakpoint-up(lg) {
								flex-direction: row-reverse;

								.card-image {
									&::before {
										left: -8px;
										right: auto;
									}
								}
							}
						}
					}

					&:nth-child(2) {
						@include media-breakpoint-up(xl) {
							transform: translateX(-30px);
						}
					}

					&:nth-child(4) {
						@include media-breakpoint-up(xl) {
							transform: translateX(30px);
						}
					}

					&:nth-child(2),
					&:nth-child(4) {
						margin-top: 30px;

						.card {
							transform: rotate(3deg);

							.card-body {
								&::before {
									transform: rotate(3deg);
								}
							}
						}
					}

					&:nth-child(3) {
						.card {
							transform: rotate(-3deg);

							.card-body {
								&::before {
									@include media-breakpoint-up(xl) {
										transform: rotate(-3deg);
									}
								}
							}
						}
					}

					.card {
						&:hover {
							transform: scale(1.01);
						}
					}
				}
			}
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	margin: 6vh 0 -3vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}
}

// icon-collection-section
// =========================================================================
.icon-collection-section {

	// collection
	.collection {
		.item {
			flex: 0 !important;
			max-width: none !important;
			width: auto !important;

			&:nth-child(odd) {
				.card {
					transform: rotate(3deg);
				}
			}
		}

		.card {
			width: 160px;
			margin: 0 auto;
			justify-content: center;
			background: $blue-dark;
			border: none;
			border-radius: 100%;
			box-shadow: $shadow;
			text-align: center;
			transform: rotate(-3deg);
			aspect-ratio: 1;

			.card-image {
				flex: none;
				margin-bottom: 15px;
				color: $yellow;
				transition: 0.5s;

				i {
					font-size: 50px;
				}
			}

			.card-body {
				flex: none;
				padding: 0;

				.card-caption {
					.card-title {
						margin-bottom: 0;
						color: $white;
						font-size: 18px;
						font-weight: 400;
						font-family: $font-family-tertiary;
					}

					.card-subtitle,
					.card-description {
						display: none;
					}
				}
			}

			&[href]:hover {
				background: $yellow;
				transform: scale(1.01);

				.card-image {
					color: $white;
				}
			}
		}
	}
}

// news-section
// =========================================================================
.news-section {
	margin: 6vh 0;
	padding: 0 15px;

	// collection
	.collection {
		&.grid {
			.grid-items {
				@include media-breakpoint-down(sm) {
					align-items: flex-start;
					justify-content: flex-start;
				}

				.item {
					@include make-col(12);

					.card {
						background: none;
						border-radius: 0;
						border: none;
						overflow: visible;

						.card-image {
							.card-image-link {
								border-radius: 15px;
								overflow: hidden;
							}
						}

						.card-body {
							@include media-breakpoint-up(md) {
								padding: 0 0 0 30px;
							}

							.card-caption {
								display: flex;
								flex-direction: column;

								@include media-breakpoint-down(sm) {
									text-align: center;
								}

								.card-title-link {
									margin: 0 0 10px 0;
									color: $blue-dark;
								}

								.card-subtitle {
									order: -1;
									margin: 0;
									font-size: 16px;
									font-family: $font-family-primary;
								}
							}

							.card-buttons {
								@include media-breakpoint-down(sm) {
									text-align: center;
								}
							}
						}
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
