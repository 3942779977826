// footer
.footer {
	position: relative;
	margin-top: 8vh;
	overflow: hidden;

	&::before {
		content: url('/images/footer-wave.svg');

		@include media-breakpoint-down(lg) {
			content: url('/images/footer-wave.png');
		}

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin-bottom: -1px;
		font-size: 0;
		line-height: 0;
		background-color: $cream;
	}

	// footer-outro
	.footer-outro {
		padding-top: calc(6vh + 30px);
		padding-bottom: 6vh;
		background-color: $blue-dark;
		color: $white;
		text-align: center;

		.image {
			width: 215px;
			margin: 0 auto 30px auto;
		}

		.footer-link {
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				li {
					margin: 5px 10px;

					a {
						display: inline-flex;
						align-items: center;
						justify-content: center;
						min-width: 260px;
						@extend .btn;
						@extend .btn-primary;

						&::before {
							margin-right: 10px;
							font-size: 16px;
							font-weight: 900;
							font-family: $font-awesome;
						}

						&[href^="tel:"]::before {
							content: "\f095";
						}

						&[href^="mailto:"]::before {
							content: "\f0e0";
						}
					}
				}
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		padding: 30px 0;

		.column {
			&.two {
				flex-flow: row wrap;
				align-items: flex-end;

				@include media-breakpoint-down(sm) {
					order: -1;
					flex-direction: column;
					align-items: center;
					text-align: center;
				}

				.footer-text {
					flex: 1;
				}
			}
		}

		// partner-logos
		.partner-logos {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: 0 -15px;
				padding: 0;
				list-style: none;

				li {
					margin: 15px;

					img {
						border-radius: 0;
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				flex-wrap: nowrap !important;
				margin-bottom: 1rem;

				li {
					margin: 0 15px;
					font-size: 36px;

					a {
						color: $orange;

						&:hover {
							color: $yellow;
						}
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 15px 0;

		// footer-text
		.footer-text {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				li {
					font-size: 14px;

					@include media-breakpoint-up(xl) {
						font-size: 16px;
					}

					@include media-breakpoint-up(sm) {
						margin: 0 15px;
					}

					a {
						color: $black;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
