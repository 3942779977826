// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue: #0978c7;
$blue-dark: #02488d;
$orange: #f4651f;
$green: #70b744;
$yellow: #f0b504;
$cream: #fefbf1;

$white: #fff;
$black: #101921;

// theme-colors
$primary: $orange;
$secondary: $blue-dark;

// body
$body-bg: $cream;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Love+Ya+Like+A+Sister&family=Ubuntu:wght@300;400;700&display=swap');
$font-family-primary: 'Ubuntu', sans-serif;
$font-family-secondary: 'Lobster', sans-serif;
$font-family-tertiary: 'Love Ya Like A Sister', cursive;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1.125; // 18px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $blue-dark;

// contextual
$light: $cream;
$dark: $green;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
