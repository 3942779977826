// btn
.btn {
	padding: 11px 30px;
	border-radius: 24px;
	box-shadow: $shadow;
	font-weight: 400;
	line-height: 24px;
	font-family: $font-family-tertiary;

	@include media-breakpoint-up(xl) {
		font-size: 18px;
	}

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: $yellow;
			border-color: $yellow;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	display: inline-flex;
	align-items: center;
	@extend .btn;
	@extend .btn-primary;

	&::after {
		content: '\f0a9';
		margin-left: 10px;
		font-size: 16px;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
