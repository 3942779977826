& {
	@include media-breakpoint-up(xl) {
		font-size: 18px;
	}
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-large {
		max-width: 810px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// headings
h1,
.h1 {
	color: $orange;
}

h5,
.h5 {
	margin-bottom: 0;
	font-family: $font-family-tertiary;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 20px;
	box-shadow: 0 3px 12px rgba($black, 0.15);

	.card-image {
		overflow: visible;

		&::before {
			content: '';
			z-index: 10;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -8px;
			height: 16px;
			background: url('/images/card-wave-horizontal.svg');
		}

		.card-image-link {
			overflow: hidden;
		}
	}

	.card-body {
		position: relative;

		@include media-breakpoint-up(xl) {
			padding: 40px 25px;
		}

		.card-caption {
			.card-title {
				color: $headings-color;
				@include font-size($h2-font-size);
			}

			.card-subtitle {
				color: $headings-color;
			}
		}
	}
}
