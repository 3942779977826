// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh);
	}
}


// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&::after {
		content: url('/images/eyecatcher-wave.svg');

		@include media-breakpoint-down(lg) {
			content: url('/images/eyecatcher-wave.png');
		}

		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	// owl-carousel
	.owl-carousel {
		.item {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 50%;
				background: linear-gradient(0deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			}

			.owl-caption-holder {
				position: relative;
			}
		}

		// owl-nav
		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}
